<template>
	<div class="splitted-container">
		<calendar
			class="calendar"
			ref="calendarComponent"
			:data="{ slug: $auth.user().slug, id: $auth.user().id, right: true, from: 'user', viewToolbar: false, filtersData: filters }"
			:viewCalendar="viewCalendar"
			:key="uniqueKeyClendar"
			v-if="Object.keys(filters).length && filters.loadedKey > 0"
		/>
		<div class="list-container">
			<div class="list">
				<div class="categories">
					<accordion-list class="category-accordion past" type="past" :arrayData="taskLogs.past" v-if="taskLogs.past && taskLogs.past.length" />
					<accordion-list class="category-accordion" type="today" :arrayData="taskLogs.today" />
					<accordion-list class="category-accordion" type="week" :arrayData="taskLogs.week" />
					<accordion-list class="category-accordion" type="month" :arrayData="taskLogs.month" />
					<accordion-list class="category-accordion" type="next_month" :arrayData="taskLogs.next_month" />
					<accordion-list class="category-accordion" type="without_date" :arrayData="taskLogs.without_date" />
				</div>
				<div class="add-task" :class="{ has_error: errorStore }" v-if="soft_organigram.is_active">
					<div class="error-msg" v-if="errorStore">
						{{ !$v.inlineTaskInput.required ? $t("validator.required") : $t("validator.maxlength_255") }}
					</div>
					<div class="input-box">
						<div class="icon-left">
							<div class="loader-spin" v-if="loadingSubmitTask"></div>
							<icon-circle-x-mark class="red" v-else-if="errorStore" />
							<icon-circle-check :class="{ green: successStore }" v-else />
						</div>
						<input type="text" v-model="inlineTaskInput" :placeholder="$t('dashboard.placeholder_add_task')" @keyup.enter="storeInlineTask" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IconCircleCheck from "../../Icons/CircleCheck";
import IconCircleXMark from "../../Icons/CircleXMarkLight";
import Calendar from "../../Pages/Calendar";
import AccordionList from "./AccordionListDefault";

import { required, maxLength } from "vuelidate/lib/validators";

export default {
	components: {
		Calendar,
		AccordionList,
		IconCircleCheck,
		IconCircleXMark,
	},
	props: {
		filterJson: String,
		viewCalendar: String,
	},
	computed: {
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
	watch: {
		viewCalendar(newData, oldData) {
			if (oldData != newData) {
				switch (newData) {
					case "monthly":
						this.$refs.calendarComponent.changeViewApi("dayGridMonth");
						break;
					case "weekly":
						this.$refs.calendarComponent.changeViewApi("timeGridWeek");
						break;
					case "daily":
						this.$refs.calendarComponent.changeViewApi("listWeek");
						break;
				}

				this.$parent.intervalCalendar(this.$refs.calendarComponent.$refs.fullCalendar.getApi().currentData.viewTitle, newData);
			}
		},
		filterJson(newData, oldData) {
			this.filters = JSON.parse(newData);

			if (oldData && this.filters.loadedKey > JSON.parse(oldData).loadedKey) {
				this.uniqueKeyClendar++;
				this.getListData();
			}
		},
	},
	validations: {
		inlineTaskInput: { required, maxLength: maxLength(255) },
	},
	data() {
		return {
			loaded: false,
			inlineTaskInput: "",
			loadingSubmitTask: false,
			errorStore: false,
			successStore: false,
			uniqueKeyClendar: 1,
			taskLogs: {
				past: [],
				today: [],
				week: [],
				month: [],
				next_month: [],
				without_date: [],
			},
			filters: {},
		};
	},
	beforeDestroy() {
		this.$root.$off("refreshSplitView");
	},
	async mounted() {
		this.filters = JSON.parse(this.filterJson);
		this.getListData();

		this.$root.$on("refreshSplitView", async () => {
			this.uniqueKeyClendar++;
			this.getListData();
		});
	},
	methods: {
		getListData() {
			this.loaded = false;

			var paramsCall = {};

			if (this.filters.sources == "personal") {
				paramsCall.assignation = this.filters.sources;
			}

			axios.get(`${this.$auth.user().slug}/logs-list-section`, { params: paramsCall }).then(({ data }) => {
				this.taskLogs.past = data.data.list.past_logs;
				this.taskLogs.today = data.data.list.today;
				this.taskLogs.week = data.data.list.this_week;
				this.taskLogs.month = data.data.list.this_month;
				this.taskLogs.next_month = data.data.list.future_period;
				this.taskLogs.without_date = data.data.list.without_date;

				this.loaded = true;
			});
		},
		storeInlineTask() {
			this.loadingSubmitTask = true;
			this.errorStore = false;

			this.$v.$touch();

			if (!this.$v.$invalid) {
				axios
					.post("tasks/store", { name: this.inlineTaskInput, user_id: this.$auth.user().id, from_calendar: 1 })
					.then(() => {
						this.inlineTaskInput = "";
						this.getListData();

						// this.taskLogs.without_date.push({
						//     date:null,
						//     deadline:null,
						//     deadline_time:null,
						//     done:0,
						//     frequency:null,
						//     id:4,
						//     log_type:"task",
						//     measurement:[],
						//     measurement_dynamically:[],
						//     name:"Task nou",
						//     parent_id:4,
						//     priority:null,
						//     unique_id:null,
						//     user:{id: 1697, avatar: "https://organigramabe.tbf.ro/storage/avatars/1632346701.jpg", first_name: "John",…},
						//     user_id:1697
						// });
					})
					.finally(() => {
						this.loadingSubmitTask = false;
						this.successStore = true;
						setTimeout(() => {
							this.successStore = false;
						}, 1000);
					});
			} else {
				this.errorStore = true;
				this.loadingSubmitTask = false;
			}
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
	},
};
</script>
